// eslint-disable-next-line
export function parseSearchKeyword(searchKeyword) {
  // const includedContributorRegExp = /(?<!-)by:([^\s]+)/;
  // const excludedContributorRegExp = /-by:([^\s]+)/;
  const includedContributorRegExp = /(?<!-)by:([a-z0-9-_.,]+)/;
  const excludedContributorRegExp = /-by:([a-z0-9-_.,]+)/;

  const includeArtistsMatch = searchKeyword.match(includedContributorRegExp);
  const excludeArtistsMatch = searchKeyword.match(excludedContributorRegExp);

  if (includeArtistsMatch) {
    const includeArtists = includeArtistsMatch ? includeArtistsMatch[1].split(',') : [];
    searchKeyword = searchKeyword.replace(includeArtistsMatch[0], '');
    return {
      keyword: searchKeyword.replace(/  +/g, ' ').trim() || '',
      contributor: [...includeArtists].join(','),
    };
  }

  if (excludeArtistsMatch) {
    const excludeArtists = excludeArtistsMatch ? excludeArtistsMatch[1].split(',') : [];
    searchKeyword = searchKeyword.replace(excludeArtistsMatch[0], '');
    return {
      keyword: searchKeyword.replace(/  +/g, ' ').trim() || '',
      contributor: [...excludeArtists.map(x => `-${x}`)].join(','),
    };
  }

  return {
    keyword: searchKeyword,
  };
}
