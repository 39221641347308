//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ClickOutside from 'vue-click-outside';
import { mapGetters, mapState } from 'vuex';
import { faSearch, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { isProductType } from '@motionelements/core/src/services/catalog.service.js';
import channelTypesMixin from '@motionelements/core/src/components/mixins/channelTypes.mixin.js';
import { parseSearchKeyword } from '@motionelements/core/src/helpers/search.js';
import { faMeSearchAi } from '@/assets/icons/me-font-icons-regular';
import { listAutoCompleteKeywords } from '@/api/search.js';

export default {
  name: 'search-bar',
  directives: {
    ClickOutside,
  },
  props: {
    focusInput: Boolean,
  },
  mixins: [channelTypesMixin],
  components: {
    SearchBarTypeaheadList: () => import('./SearchBarTypeaheadList.vue'),
    SearchChannelTypesDropdown: () => import('@motionelements/core/src/components/ui/search-box/SearchChannelTypesDropdown.vue'),
  },
  data() {
    return {
      faSearch,
      faXmark,
      faMeSearchAi,
      keyword: '',
      channelType: 'video',
      typeahead: {
        keyword: null,
        mediaType: null,
        keywords: [],
        isLoading: false,
      },
      isFocused: false,
      isChannelTypeError: false,
    };
  },
  watch: {
    keyword(keyword) {
      if (keyword) {
        this.isFocused = true;
        if (this.channelType) this.updateTypeHeads(keyword);
      }
    },
    currentChannelType(value) {
      if (value) {
        this.channelType = value;
      }
    },
    focusInput(value) {
      if (value) {
        this.$nextTick(() => {
          this.isFocused = true;
          this.$refs.keywordInput.focus();
        });
      }
    },
  },
  mounted() {
    if (this.focusInput) {
      this.$nextTick(() => {
        this.$refs.keywordInput.focus();
        this.isFocused = true;
      });
    }
    if (this.currentChannelType) {
      this.channelType = this.currentChannelType;
    }
  },
  computed: {
    ...mapState({
      currentChannelType: state => state.channels.channelType,
      popularCategories: state => state.searchBox.popularCategories,
    }),
    ...mapGetters({
      history: 'searchBox/history',
    }),
    heading() {
      return isProductType('audio', this.channelType)
        ? this.$t('search.audio_search')
        : this.$t('search.visual_search');
    },
  },
  methods: {
    onBlur() {
      // without setTimeout cant select mediaType
      setTimeout(() => {
        this.isFocused = false;
      }, 100);
    },
    onChannelTypeSelect(channelTypeId) {
      this.$store.dispatch('channels/setChannelType', channelTypeId);
      this.isChannelTypeError = false;
      this.$refs.keywordInput.focus();

      if (this.keyword.length) {
        this.listAutoCompleteKeywords(this.keyword).then(() => {
          this.isFocused = true;
        });
      }
    },
    doSearchKeyword() {
      if (this.isChannelTypeError) {
        return;
      }
      if (this.channelType) {
        const parsedKeyword = parseSearchKeyword(this.keyword);
        this.keyword = parsedKeyword.keyword;
        this.$store.commit('searchBox/addHistory', this.keyword);
        window.location.href = this.searchLink(parsedKeyword);
      } else {
        this.isChannelTypeError = true;
        setTimeout(() => {
          this.isChannelTypeError = false;
        }, 2500);
      }
    },
    updateTypeHeads: _.debounce(function (text) {
      this.listAutoCompleteKeywords(text);
    }, 500),
    searchLink({ keyword, contributor }) {
      const link = this.$link(`/search/${(this.channelType || '').replace('_', '-')}`)
        .param('s', keyword)
        .param('sort', 'match');
      if (contributor) {
        link.param('by', contributor);
      }
      return link.url();
    },
    searchInCategory(keyword) {
      this.$store.commit('searchBox/addHistory', keyword);
      window.location.href = this.searchLink(keyword);
    },
    handleFocus() {
      this.isFocused = true;
      if (this.keyword && this.channelType) this.listAutoCompleteKeywords(this.keyword);
    },
    listAutoCompleteKeywords(text) {
      text = parseSearchKeyword(text).keyword;
      // avoid searching for the current keyword
      if (this.typeahead.keyword === text && this.typeahead.mediaType === this.channelType) {
        return;
      }
      const params = {
        q: text,
      };

      // clear first
      this.typeahead.keywords = [];
      this.typeahead.isLoading = true;
      return listAutoCompleteKeywords(this.channelType, params)
        .then((response) => {
          // /suggest response is not wrapped in data.data
          this.typeahead.keywords = response.data;
          return response;
        })
        .finally(() => {
          this.typeahead.isLoading = false;
          this.typeahead.keyword = text;
          this.typeahead.mediaType = this.channelType;
        });
    },
    handleHit(keyword) {
      this.$store.commit('searchBox/addHistory', keyword);
      window.location.href = this.searchLink(keyword);
    },
  },
};
